<template>
  <div class="a15-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-input
            type="text"
            v-model="searchModel.meterageDisplayCode"
            placeholder="子目号"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item v-if="showSearchDetail">
          <el-input
            type="text"
            v-model="searchModel.meterageName"
            placeholder="子目名称"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item v-if="isAccountList">
          <el-input
            type="text"
            v-model="searchModel.accountListId"
            placeholder="台账编号"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model.number="searchModel.isLock">
            <el-option label="全部" :value="2"></el-option>
            <el-option label="未锁定" :value="0"></el-option>
            <el-option label="锁定" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="auditDataColumns.length">
          <el-select v-model="searchModel.audit" clearable>
            <el-option
              v-for="dataColumn of auditDataColumns"
              :key="dataColumn.identityGuid"
              :label="dataColumn.stepName"
              :value="dataColumn.identityGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="showSearchDetail">
          <el-input
            type="text"
            v-model="searchModel.startMileage"
            placeholder="起始桩号"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item v-if="showSearchDetail">
          <el-input
            type="text"
            v-model="searchModel.endMileage"
            placeholder="终止桩号"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item v-if="showSearchDetail">
          <el-input
            type="text"
            v-model="searchModel.part"
            placeholder="部位"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item v-if="1 === 0">
          <el-input
            type="text"
            v-model="searchModel.pictureNo">
            placeholder="图号"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item v-if="showSearchDetail">
          <el-select v-model="searchModel.userGuid" clearable>
            <el-option
              v-for="item of createUserList"
              :key="item.userGuid"
              :label="item.userName"
              :value="item.userGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button @click="handleSearchText">{{ searchText }}</el-button>
        </el-form-item>
        <br>
        <el-form-item>
          <el-button v-if="couldLock" type="primary" @click="handleBatchLock(1)">锁定</el-button>
          <el-button v-if="couldLock" type="primary" @click="handleBatchLock(0)">解锁</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleAddClick">添加合同内计量</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleAddChangeClick">添加合同外计量</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleAddAccountListClick">添加台账计量</el-button>
          <el-tooltip v-if="showImportButton" class="item" effect="light" content="导入B06、F08、目录、A07、A07-1、F08-1" :open-delay="500" placement="top">
            <el-button type="primary" @click="handleB06Click">模板导入</el-button>
          </el-tooltip>
          <el-button type="primary" @click="handleBatchPicture(1)">绑定草图</el-button>
          <el-button type="primary" @click="handleBatchPicture(0)">删除草图</el-button>
          <el-button type="primary" @click="handleBindPicture">批量绑定草图</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleBatchDelete()">批量删除</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleDeleteAll()">全部删除</el-button>
          <el-button type="primary" @click="handleExportExcel()">导出excel</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <my-el-table
      v-if="dataColumns.length"
      :data="a15List"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      :selection="true"
      keyFiled="a15Guid"
      :show-footer="true"
      :row-class-name="rowClassName"
      @select-all-change="handleSelectAllChange"
      @selection-change="handleSelectionChange"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
      :myTableHeight="myTableHeight"
      >
      <el-table-column fixed="left" prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column fixed="left" prop="directive" :formatter="changeFormatter" width="60" header-align="center" align="center" label="变更"></el-table-column>
      <el-table-column fixed="left" prop="meterageDisplayCode" width="130" header-align="left" align="left" label="子目号"></el-table-column>
      <el-table-column fixed="left" prop="meterageName" width="260" header-align="left" align="left" label="子目名称"></el-table-column>
      <el-table-column fixed="left" v-if="isAccountList" prop="accountListId" width="260" header-align="left" align="left" label="台账编号"></el-table-column>
      <el-table-column prop="unit" width="50" header-align="center" align="center" label="单位"></el-table-column>
      <el-table-column prop="price" width="120" header-align="center" align="center" label="合同单价"></el-table-column>
      <el-table-column prop="amount" width="120" header-align="center" align="center" label="合同金额"></el-table-column>
      <el-table-column prop="pictureName" width="200" header-align="center" align="center" label="草图"
        :show-overflow-tooltip="true"
        >
      </el-table-column>
      <el-table-column
        width="150"
        header-align="center"
        :label-class-name="showAddBtn ? 'editable' : ''"
        align="center"
        label="流水号">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row, 0, true)"
            v-if="showAddBtn && !scope.row.isLock"
            v-model="scope.row.manualSerialNo">
          </editable-table-cell>
          <span v-else>{{ scope.row.manualSerialNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="150"
        header-align="center"
        label-class-name="editable"
        align="center"
        label="日期">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row, 0, false)"
            v-if="!scope.row.isLock"
            v-model="scope.row.a15Date">
          </editable-table-cell>
          <span v-else>{{ scope.row.a15Date }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="100"
        header-align="center"
        :label-class-name="showAddBtn ? 'editable' : ''"
        :show-overflow-tooltip="true"
        align="center"
        label="起始桩号">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row, 0, true)"
            v-if="showAddBtn && !scope.row.isLock"
            v-model="scope.row.startMileage">
          </editable-table-cell>
          <span v-else>{{ scope.row.startMileage }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="100"
        header-align="center"
        :show-overflow-tooltip="true"
        :label-class-name="showAddBtn ? 'editable' : ''"
        align="center"
        label="终止桩号">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row, 0, true)"
            v-if="showAddBtn && !scope.row.isLock"
            v-model="scope.row.endMileage">
          </editable-table-cell>
          <span v-else>{{ scope.row.endMileage }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="260"
        header-align="left"
        label-class-name="editable"
        align="left"
        label="工程部位">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row, 0, true)"
            v-model="scope.row.part">
          </editable-table-cell>
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        header-align="center"
        label-class-name="editable"
        :show-overflow-tooltip="true"
        align="center"
        label="图号">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row, 0, false)"
            v-if="!scope.row.isLock"
            v-model="scope.row.pictureNo">
          </editable-table-cell>
          <span v-else>{{ scope.row.pictureNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="120"
        header-align="center"
        label-class-name="editable"
        align="center"
        label="中间交工证书号">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row, 0, false)"
            v-if="!scope.row.isLock"
            v-model="scope.row.certNo">
          </editable-table-cell>
          <span v-else>{{ scope.row.certNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="260"
        header-align="left"
        label-class-name="editable"
        align="left"
        :show-overflow-tooltip="true"
        label="计算公式">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row, 0, false)"
            v-model="scope.row.formula">
          </editable-table-cell>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(column, index) in dataColumns"
        :key="column.identityGuid"
        width="120"
        header-align="center"
        align="center"
        :label-class-name="classFilter(column)"
        :label="column.stepName + '数量'"
        >
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row.children[index], 1)"
            type="number"
            v-if="showImportButton && !scope.row.isLock && userIdentityGuid === scope.row.children[index].identityGuid && approvalIdentityGuid === userIdentityGuid && !readonly"
            v-model="scope.row.children[index].num">
          </editable-table-cell>
          <span v-else>{{ scope.row.children[index].num }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="260"
        header-align="left"
        label-class-name="editable"
        align="left"
        :show-overflow-tooltip="true"
        label="备注">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row, 0, false)"
            v-model="scope.row.remark">
          </editable-table-cell>
        </template>
      </el-table-column>
      <el-table-column prop="createUserGuid" :formatter="userNameFormatter" width="80" header-align="center" align="center" label="创建人"></el-table-column>
      <el-table-column prop="isLock" :formatter="isLockFormatter" width="70" header-align="center" align="center" label="状态"></el-table-column>
      <el-table-column label="归零" width="50" align="center">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <a v-if="showImportButton && !scope.row.isLock && approvalIdentityGuid === userIdentityGuid && !readonly"
            @click="handleZeroClick(scope.row)" href="#">
            <el-tooltip class="item" effect="light" content="归零" :open-delay="500" placement="top">
              <img style="width: 15px;" src="@/assets/images/svg/zero.svg" alt="">
            </el-tooltip>
          </a>
          <a v-else href="#">
            <el-tooltip class="item" effect="light" content="归零" :open-delay="500" placement="top">
              <img style="width: 15px;" src="@/assets/images/svg/zero-gray.svg" alt="">
            </el-tooltip>
          </a>
        </template>
      </el-table-column>
      <el-table-column label="附件" width="50" align="center">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <a @click="handleAttachmentClick(scope.row)" href="#">
            <el-tooltip class="item" effect="light" content="附件" :open-delay="500" placement="top">
              <img style="width: 15px;" src="@/assets/images/svg/attachment.svg" alt="">
            </el-tooltip>
          </a>
        </template>
      </el-table-column>
      <el-table-column v-if="showAddBtn" width="50" label="操作" header-align="center" align="center">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" v-if="!scope.row.isLock" class="text-danger far fa-trash-alt operation-button"></i>
            <i v-else class="text-muted far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <slot slot="pagerText"><div style="color: red;">说明：本页显示合计金额为本期申报明细累计金额，最终批复金额以计量报表中显示为准。</div><div>合计金额：{{ totalAmount }}元</div></slot>
    </my-el-table>

    <my-dialog
      ref="myDialog"
      title="选择草图"
      size="xl"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="handleOk"
      >
      <picture-index
        ref="pictureIndex"
        :selection="true"
        >
      </picture-index>
    </my-dialog>

    <my-dialog
      ref="b06Dialog"
      title="导入B06、F08、目录、A07、A07-1、F08-1"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="handleImportB06"
      >
      <el-upload
        class="upload-b06"
        ref="upload"
        :action="uploadAction"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :on-error="handleError"
        :limit="1"
        accept=".xls,.xlsx"
        :data="uploadData"
        :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <div slot="tip" class="el-upload__tip">只能上传Excel文件，且不超过10MB</div>
      </el-upload>
    </my-dialog>

    <my-dialog
      ref="attachmentDialog"
      size="xl"
      title="附件列表"
      cancel-text="关闭"
      :hide-ok="true"
      >
      <el-form inline>
        <el-form-item>
          <el-upload
            class="upload-attachment"
            ref="upload"
            :action="attachmentUploadAction"
            :on-remove="handleAttachmentRemove"
            :on-success="handleAttachmentSuccess"
            :on-error="handleAttachmentError"
            :show-file-list="true"
            :file-list="attachmentFileList"
            :limit="1"
            :data="attachmentUploadData"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">选取附件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleBeforeUpload">上传</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="attachmentList"
        border
        header-cell-class-name="bg-info text-light"
        >
        <el-table-column align="center" prop="OriginalFileName" header-align="center" label="文件名"></el-table-column>
        <el-table-column align="center" prop="FilesType" header-align="center" label="文件类型"></el-table-column>
        <el-table-column align="center" prop="FileSize" header-align="center" label="文件大小"></el-table-column>
        <el-table-column align="center" :formatter="timeFormatter" prop="CreateTime" header-align="center" label="上传时间"></el-table-column>
        <el-table-column header-align="center" align="center" width="100" label="操作">
          <template slot-scope="scope">
            <a :href="reportPrefix + scope.row.SavePath" target="_blank" :download="scope.row.OriginalFileName" class="mr-2">下载</a>
            <a href="#" @click="handleAttachmentDeleteClick(scope.row)">删除</a>
          </template>
        </el-table-column>
      </el-table>
    </my-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import PictureIndex from '@/views/PictureManage/PictureIndex'
import MyElTable from '@/components/MyElTable/MyElTable'
import A15Model from '@/model/A15Model'
import a15Service from '@/services/a15Service'
import PageOperation from '@/mixins/PageOperation'
import PageInfo from '@/support/PageInfo'
import { mapGetters } from 'vuex'
import auth from '@/common/auth'
import config from '@/config'
import utility from '@/common/utility'
import EditableTableCell from '@/views/ExaminationManage/Components/EditableTableCell'
import examinationService from '@/services/examinationService'
import flowService from '@/services/flowService'

export default {
  name: 'A15Index',
  mixins: [
    PageOperation
  ],
  components: {
    PictureIndex,
    MyElTable,
    MyDialog,
    EditableTableCell,
    MySearchBar
  },
  filters: {
  },
  computed: {
    searchText () {
      return this.showSearchDetail ? '简单搜索' : '高级搜索'
    },
    showImportButton () {
      // todo 绥大始终显示
      return this.approvalStatus !== '最终批复' || auth.getUserInfo().projectGuid === 'e5116f60-a3b3-11ea-8237-4544fa13c61d' || auth.getUserInfo().projectGuid === '1648b510-45df-11ec-be27-89b2fc28850f' || auth.getUserInfo().projectGuid === 'd72f1d30-9b54-11ec-bd7b-6b66b1627126' || auth.getUserInfo().projectGuid === 'ee8fdfd0-f5dc-11ed-adea-b7100d14708c'
    },
    isAccountList () {
      return auth.getUserInfo().projectGuid === '45afaea0-8cfd-11ee-a270-255dd4c0dc42'
    },
    reportPrefix () {
      return 'http://47.93.222.187:5009'
    },
    myTableHeight () {
      return document.body.clientHeight - 340
    },
    userGuid () {
      return auth.getUserInfo().userGuid
    },
    userIdentityGuid () {
      return auth.getUserInfo().identityGuid
    },
    couldLock () {
      return config.applyIdentities.indexOf(auth.getUserInfo().identityGuid) === -1
    },
    showAddBtn () {
      let flag = this.$route.query['readonly']
      if (!flag) {
        flag = 'false'
      }
      return this.approvalStatus !== '最终批复' && config.applyIdentities.indexOf(auth.getUserInfo().identityGuid) > -1 && this.approvalIdentityGuid === auth.getUserInfo().identityGuid && flag === 'false'
    },
    readonly () {
      return this.$route.query['readonly'] !== 'false'
    },
    dataColumns () {
      console.log(this.a15List)
      if (this.a15List.length) {
        console.log(this.a15List[0].children)
        return this.a15List[0].children
      }
      return []
    },
    ...mapGetters('examination', {
      examinationGuid: 'getExaminationGuid',
      tenderGuid: 'getTenderGuid',
      periodName: 'getPeriodName',
      approvalUserGuid: 'getApprovalUserGuid',
      approvalStatus: 'getApprovalStatus',
      approvalIdentityGuid: 'getApprovalIdentityGuid'
    })
  },
  data () {
    return {
      testValue: 1,
      total: 0,
      a15List: [],
      a15Model: new A15Model(),
      searchModel: {
        accountListId: '',
        examinationGuid: '',
        meterageDisplayCode: '',
        meterageName: '',
        chapter: '',
        startMileage: '',
        endMileage: '',
        part: '',
        pictureNo: '',
        certNo: '',
        formula: '',
        isLock: 2,
        audit: '',
        userGuid: ''
      },
      selectedValues: [],
      totalAmount: '',
      uploadData: {},
      uploadAction: `${config.reportHost}/UploadFile`,
      attachmentUploadData: {},
      attachmentFileList: [],
      attachmentUploadAction: `${config.reportHost}/UploadFile`,
      attachmentList: [],
      showSearchDetail: true,
      auditDataColumns: [],
      createUserList: []
    }
  },
  methods: {
    handleExportExcel () {
      window.location.href = `http://47.93.222.187:5035/a15s/excel?examinationGuid=${this.examinationGuid}`
    },
    handleSearchText () {
      this.showSearchDetail = !this.showSearchDetail
    },
    downloadImage (row) {
      axios.get(`${this.reportPrefix}${row.SavePath}`, {
        responseType: 'blob'
      })
        .then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
    },
    changeFormatter (row) {
      if (row.directive) {
        return '是'
      }
      return ''
    },
    userNameFormatter (row) {
      return this.createUserList.find(item => item.userGuid === row.createUserGuid)?.userName
    },
    handleZeroClick (row) {
      const identityItem = row.children.find(item => item.identityGuid === auth.getUserInfo().identityGuid)
      identityItem.num = 0
      this._approval(identityItem, false)
    },
    handleAttachmentClick (row) {
      this.a15Model = new A15Model(row)
      this.attachmentList = []
      console.log(this.a15Model)

      this.$refs.attachmentDialog.open()
      this.getAttachmentList()
    },
    handleAttachmentDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let timestamp = utility.getCurrentTimestamp()
          let token = utility.getMd5(`${auth.getUserInfo().tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

          axios.post(`${config.reportHost}/DeleteFile?timestamp=${timestamp}&parameter=${token}&AttachmentGuid=${row.AttachmentGuid}&userguid=${auth.getUserInfo().userGuid}`)
            .then(res => {
              if (res.data.Code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this.getAttachmentList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.Msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败，请重试！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleBeforeUpload () {
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.a15Model.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      this.attachmentUploadAction = `${config.reportHost}/UploadFile?date=${utility.getCurrentTime(8)}&isanalysi=0&examination=${this.a15Model.a15Guid}&userguid=${this.a15Model.createUserGuid}&timestamp=${timestamp}&parameter=${token}&Category=0`

      this.uploadData = {
        date: utility.getCurrentTime(8),
        isanalysi: 0,
        examination: this.a15Model.a15Guid,
        userguid: this.a15Model.createUserGuid,
        timestamp: timestamp,
        parameter: token
      }

      this.$nextTick(() => {
        this.$refs.upload.submit()
      })

      return false
    },
    handleAttachmentRemove () {
    },
    handleAttachmentSuccess () {
      this.$nextTick(() => {
        this.fileList = []
      })
      this.getAttachmentList()

      this.$message({
        type: 'success',
        message: '上传成功！'
      })
    },
    handleAttachmentError () {
      this.$message({
        type: 'error',
        message: '上传失败！'
      })
    },
    getAttachmentList () {
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.a15Model.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()
      console.log('token', `${this.a15Model.tenderGuid}-${timestamp}-${config.fastReportKey}`)

      this.attachmentList = []
      axios.get(`${config.reportHost}/GetFileList`, {
        params: {
          examination: this.a15Model.a15Guid,
          userguid: this.a15Model.createUserGuid,
          timestamp: timestamp,
          parameter: token
        }
      }).then(res => {
        if (res.data.Code === 1) {
          this.attachmentList = res.data.Msg.map(item => {
            item.FileSize = utility.floatDivide(item.FileSize, 1024).toFixed(2) + ' KB'
            return item
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.Msg
          })
        }
      }).catch(err => {
        console.log(err)
        this.$message({
          type: 'error',
          message: '获取附件失败，请重试！'
        })
      })
    },
    rowClassName ({ row, rowIndex }) {
      if (row && row.children && row.children.length > 1 && row.children[row.children.length - 1].num !== row.children[0].num) {
        return 'warning-row'
      }
      return ''
    },
    handleB06Click () {
      this.$refs.b06Dialog.open()
    },
    handleImportB06 () {
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${auth.getUserInfo().tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      this.uploadAction = `${config.reportHost}/UploadFile?date=${utility.getCurrentTime(8)}&isanalysi=1&examination=${this.examinationGuid}&userguid=${auth.getUserInfo().userGuid}&timestamp=${timestamp}&parameter=${token}&Category=0`

      this.uploadData = {
        date: utility.getCurrentTime(8),
        isanalysi: 1,
        examinations: this.examinationGuid,
        userguid: auth.getUserInfo().userGuid,
        timestamp: timestamp,
        parameter: token
      }

      this.$nextTick(() => {
        this.$refs.upload.submit()
      })
    },
    handleRemove () {
    },
    handleSuccess () {
      this.$message({
        type: 'success',
        message: '导入成功，您可到计量报表中查看！'
      })
      this.$refs.b06Dialog.close()
    },
    handleError () {
      this.$message({
        type: 'error',
        message: '导入失败！'
      })
      this.$refs.b06Dialog.close()
    },
    classFilter (column) {
      if (this.approvalStatus !== '最终批复' && column.identityGuid === this.userIdentityGuid && this.userIdentityGuid === this.approvalIdentityGuid) {
        return 'editable'
      }
      return ''
    },
    handleDeleteAll () {
      this.$confirm('您确定要全部删除？！')
        .then(() => {
          this.$loading()
          examinationService.deleteA15(this.examinationGuid)
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '操作成功！'
                })
                this._getA15List()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '操作失败！'
              })
            })
            .finally(() => {
              this.$loading().close()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    handleBatchDelete () {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      this.$confirm('您确定要进行批量删除吗？')
        .then(() => {
          this.$loading()
          a15Service.batchDelete(this.selectedValues, this.examinationGuid)
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '操作成功！'
                })
                this._getA15List()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '操作失败，请重试！'
              })
            })
            .finally(() => {
              this.$loading().close()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    handleBindPicture () {
      this.$router.push(`/bindPicture?examinationGuid=${this.examinationGuid}`)
    },
    handleBatchPicture (flag) {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      if (flag) {
        // open image selection ui
        this.$refs.myDialog.open()
      } else {
        this.doBatchPicture('')
      }
    },
    handleOk () {
      if (!this.$refs.pictureIndex.getSelectedPicture()) {
        this.$message({
          type: 'warning',
          message: '请选择要绑定的草图！'
        })
        return
      }
      this.$refs.myDialog.close()
      this.doBatchPicture(this.$refs.pictureIndex.getSelectedPicture().pictureGuid)
    },
    doBatchPicture (pictureGuid) {
      a15Service.batchPicture(this.selectedValues, pictureGuid)
        .then(res => {
          if (res.data.code === 1) {
            this._getA15List()
            this.$message({
              type: 'success',
              message: (pictureGuid ? '绑定草图' : '删除草图') + '成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: (pictureGuid ? '绑定草图' : '删除草图') + '失败！'
          })
        })
    },
    handleBatchLock (isLocked) {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      a15Service.batchLock(this.examinationGuid, this.selectedValues, isLocked)
        .then(res => {
          if (res.data.code === 1) {
            this._getA15List()
            this.$message({
              type: 'success',
              message: (isLocked ? '锁定' : '解锁') + '成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: (isLocked ? '锁定' : '解锁') + '失败！'
          })
        })
    },
    handleBlur (row, type = 0, important = false) {
      console.log(row, type, important)
      if (type) {
        console.log('a15Identity', row)
        this._approval(row)
      } else {
        this.a15Model = new A15Model(row)
        if (important) {
          this._editImportant()
        } else {
          this._edit()
        }
      }
    },
    handleSearchClick () {
      this._getA15List()
    },
    handleAddClick () {
      this.$router.push('/a15Detail')
    },
    handleAddChangeClick () {
      this.$router.push('/a15ChangeDetail')
    },
    handleAddAccountListClick () {
      this.$router.push('/a15AccountListDetail')
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _a15Model = new A15Model(row)
          a15Service.delete(_a15Model.resourceId, _a15Model.examinationGuid, this.periodName)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getA15List()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleSelectionChange (newVal) {
      this.selectedValues = newVal
      console.log('selectedValues', this.selectedValues)
    },
    handleSelectAllChange (checkAll) {
      this.a15List.forEach(a15 => {
        a15.checked = checkAll
      })
    },
    isLockFormatter (row) {
      if (row.empty) {
        return ''
      }
      return row.isLock ? '锁定' : '未锁定'
    },
    _getA15List () {
      this.$myLoading()
      this.selectedValues = []
      this.a15List = []
      this.$nextTick(() => {
        this.searchModel.examinationGuid = this.examinationGuid
        a15Service.list(new PageInfo({ pageSize: this.pageSize, pageIndex: this.pageIndex, fieldOrder: 'MeterageLists.sortId,A15s.StartMileage,A15s.EndMileage,A15s.Part asc' }), this.searchModel)
          .then(res => {
            this.$loading().close()
            if (res.data.code === 1) {
              this.total = res.data.pageCount
              if (res.data.sumData && res.data.sumData.length) {
                this.totalAmount = res.data.sumData.filter(sum => sum.identityGuid === config.sumAmountIdentity)[0].amount
              } else {
                this.totalAmount = 0
              }
              this.a15List = res.data.data.map(a15 => {
                a15.checked = false
                return a15
              })

              this.$message({
                type: 'success',
                message: '列表加载成功！'
              })
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
          .catch(err => {
            console.log(err)
            this.$loading().close()
            this.$message({
              type: 'error',
              message: '列表加载失败！'
            })
          })
      })
    },
    _approval (a15Identity, refresh = true) {
      a15Service.approval(a15Identity.a15Guid, a15Identity.identityGuid, a15Identity.num, a15Identity.sortId, this.examinationGuid, this.periodName)
        .then(res => {
          if (res.data.code === 1) {
            if (refresh) {
              this._getA15List()
            }
            this.$message({
              type: 'success',
              message: '修改数量成功！'
            })
          } else {
            this._getA15List()
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this._getA15List()
          this.$message({
            type: 'error',
            message: '修改数量失败！'
          })
        })
    },
    _editImportant () {
      a15Service.editImportant(this.a15Model)
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this._getA15List()
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this._getA15List()
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          console.log(err.request.response)
        })
    },
    _edit () {
      a15Service.edit(this.a15Model)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this._getA15List()
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this._getA15List()
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          console.log(err.request.response)
        })
    },
    getAuditDataColumns () {
      flowService.list(new PageInfo({ pageIndex: 1, pageSize: 9999, fieldOrder: 'SortId asc' }), { projectGuid: auth.getUserInfo().projectGuid })
        .then(res => {
          if (res.data.code === 1) {
            res.data.data.shift()
            this.auditDataColumns = res.data.data.map(column => {
              column.stepName += '审核数'
              return column
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getCreateUserList () {
      a15Service.getCreateUserList(this.examinationGuid).then(res => {
        if (res.data.code === 1) {
          this.createUserList = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  created () {
    this.getAuditDataColumns()
    this.getCreateUserList()
    this._getA15List()
    this.refreshMethod = this._getA15List
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
::v-deep .el-table__fixed-body-wrapper .el-table__body {
  padding-bottom: 10px;
}
::v-deep .editable {
  color: red;
}
::v-deep .el-upload {
  text-align: left;
}
::v-deep .warning-row:hover td {
  background-color: rgba(#ff0000, .3) !important;
}
::v-deep .warning-row td {
  background-color: rgba(#ff0000, .4);
  color: #222;
}
::v-deep .el-input {
  width: 150px !important;
}
</style>
