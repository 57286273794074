<template>
  <div class="a25-index">
    <el-form>
      <el-form-item>
        <el-tooltip v-if="showImportButton" class="item" effect="light" content="导入B06、F08、目录、A07、A07-1、F08-1" :open-delay="500" placement="top">
          <el-button type="primary" @click="handleB06Click">模板导入</el-button>
        </el-tooltip>
      </el-form-item>
    </el-form>
    <my-el-table
      :data="a25List"
      :total="total"
      :show-pager="false"
      keyFiled="a25Guid"
      >
      <el-table-column prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="listName" header-align="center" align="center" label="款项"></el-table-column>
      <el-table-column
        header-align="center"
        :label-class-name="showAddBtn ? 'editable' : ''"
        align="center"
        label="金额">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row, 0)"
            v-if="showAddBtn && !scope.row.isLock"
            v-model="scope.row.amount1">
          </editable-table-cell>
          <span v-else>{{ scope.row.amount1 }}</span>
        </template>
      </el-table-column>
    </my-el-table>

    <my-dialog
      ref="b06Dialog"
      title="导入B06、F08、目录、A07、A07-1、F08-1"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="handleImportB06"
    >
      <el-upload
        class="upload-b06"
        ref="upload"
        :action="uploadAction"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :on-error="handleError"
        :limit="1"
        accept=".xls,.xlsx"
        :data="uploadData"
        :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <div slot="tip" class="el-upload__tip">只能上传Excel文件，且不超过10MB</div>
      </el-upload>
    </my-dialog>
  </div>
</template>

<script>
import MyElTable from '@/components/MyElTable/MyElTable'
import MyDialog from '@/components/MyDialog/MyDialog'
import a25Service from '@/services/a25Service'
import { mapGetters } from 'vuex'
import auth from '@/common/auth'
import EditableTableCell from '@/views/ExaminationManage/Components/EditableTableCell'
import config from '@/config'
import utility from '@/common/utility'

export default {
  name: 'A25Index',
  mixins: [
  ],
  components: {
    MyDialog,
    MyElTable,
    EditableTableCell
  },
  filters: {
  },
  computed: {
    showImportButton () {
      // todo 绥大始终显示
      return this.approvalStatus !== '最终批复' || auth.getUserInfo().projectGuid === 'e5116f60-a3b3-11ea-8237-4544fa13c61d' || auth.getUserInfo().projectGuid === 'd72f1d30-9b54-11ec-bd7b-6b66b1627126'
    },
    userGuid () {
      return auth.getUserInfo().userGuid
    },
    userIdentityGuid () {
      return auth.getUserInfo().identityGuid
    },
    showAddBtn () {
      // return config.applyIdentities.indexOf(auth.getUserInfo().identityGuid) > -1 && this.approvalUserGuid === auth.getUserInfo().userGuid
      return this.approvalStatus !== '最终批复' && this.approvalUserGuid === auth.getUserInfo().userGuid && this.$route.query['readonly'] === 'false'
    },
    ...mapGetters('examination', {
      examinationGuid: 'getExaminationGuid',
      tenderGuid: 'getTenderGuid',
      periodGuid: 'getPeriodGuid',
      approvalUserGuid: 'getApprovalUserGuid',
      approvalStatus: 'getApprovalStatus'
    })
  },
  data () {
    return {
      testValue: 1,
      total: 0,
      a25List: [],
      a25Model: null,
      searchModel: {
        tenderGuid: '',
        periodGuid: ''
      },
      totalAmount: '',
      uploadData: {},
      uploadAction: `${config.reportHost}/UploadFile`
    }
  },
  methods: {
    handleB06Click () {
      this.$refs.b06Dialog.open()
    },
    handleRemove () {
    },
    handleSuccess () {
      this.$message({
        type: 'success',
        message: '导入成功，您可到计量报表中查看！'
      })
      this.$refs.b06Dialog.close()
    },
    handleError () {
      this.$message({
        type: 'error',
        message: '导入失败！'
      })
      this.$refs.b06Dialog.close()
    },
    handleImportB06 () {
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${auth.getUserInfo().tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      this.uploadAction = `${config.reportHost}/UploadFile?date=${utility.getCurrentTime(8)}&isanalysi=1&examination=${this.examinationGuid}&userguid=${auth.getUserInfo().userGuid}&timestamp=${timestamp}&parameter=${token}&Category=0`

      this.uploadData = {
        date: utility.getCurrentTime(8),
        isanalysi: 1,
        examinations: this.examinationGuid,
        userguid: auth.getUserInfo().userGuid,
        timestamp: timestamp,
        parameter: token
      }

      this.$nextTick(() => {
        this.$refs.upload.submit()
      })
    },
    handleBlur (row, type = 0) {
      if (type) {
        console.log('a25Identity', row)
        this._approval(row)
      } else {
        this.a25Model = row
        row.amount1 = isNaN(parseFloat(row.amount1)) ? 0 : parseFloat(row.amount1)
        this._edit()
      }
    },
    _getA25List () {
      this.searchModel.tenderGuid = this.tenderGuid
      this.searchModel.periodGuid = this.periodGuid
      a25Service.list({}, this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.a25List = res.data.data.map(a25 => {
              return a25
            })
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _edit () {
      a25Service.edit(this.a25Model)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this._getA25List()
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this._getA25List()
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getA25List()
    this.refreshMethod = this._getA25List
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
::v-deep .editable {
  color: red;
}
</style>
