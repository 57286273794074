<template>
  <div class="a25-index">
    <el-button type="primary" @click="handleExportExcel()">导出excel</el-button>
    <div style="height: 10px;"></div>
    <my-el-table
      :data="a25List"
      :total="total"
      :show-pager="false"
      keyFiled="a25Guid"
      >
      <el-table-column prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="listName" header-align="center" align="center" label="款项"></el-table-column>
      <el-table-column
        header-align="center"
        :label-class-name="showAddBtn ? 'editable' : ''"
        align="center"
        label="金额">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row, 0)"
            v-if="showAddBtn && !scope.row.isLock"
            v-model="scope.row.amount1">
          </editable-table-cell>
          <span v-else>{{ scope.row.amount1 }}</span>
        </template>
      </el-table-column>
    </my-el-table>

  </div>
</template>

<script>
import MyElTable from '@/components/MyElTable/MyElTable'
import a25Service from '@/services/a25Service'
import { mapGetters } from 'vuex'
import auth from '@/common/auth'
import EditableTableCell from '@/views/ExaminationManage/Components/EditableTableCell'

export default {
  name: 'A25Index',
  mixins: [
  ],
  components: {
    MyElTable,
    EditableTableCell
  },
  filters: {
  },
  computed: {
    userGuid () {
      return auth.getUserInfo().userGuid
    },
    userIdentityGuid () {
      return auth.getUserInfo().identityGuid
    },
    showAddBtn () {
      // return config.applyIdentities.indexOf(auth.getUserInfo().identityGuid) > -1 && this.approvalUserGuid === auth.getUserInfo().userGuid
      return this.approvalStatus !== '最终批复' && this.approvalUserGuid === auth.getUserInfo().userGuid && this.$route.query['readonly'] === 'false'
    },
    ...mapGetters('examination', {
      tenderGuid: 'getTenderGuid',
      examinationGuid: 'getExaminationGuid',
      periodGuid: 'getPeriodGuid',
      approvalUserGuid: 'getApprovalUserGuid',
      approvalStatus: 'getApprovalStatus'
    })
  },
  data () {
    return {
      testValue: 1,
      total: 0,
      a25List: [],
      a25Model: null,
      searchModel: {
        tenderGuid: '',
        periodGuid: ''
      },
      totalAmount: ''
    }
  },
  methods: {
    handleExportExcel () {
      window.location.href = `http://47.93.222.187:5035/g25/excel?examinationGuid=${this.examinationGuid}`
    },
    handleBlur (row, type = 0) {
      if (type) {
        console.log('a25Identity', row)
        this._approval(row)
      } else {
        this.a25Model = row
        row.amount1 = isNaN(parseFloat(row.amount1)) ? 0 : parseFloat(row.amount1)
        this._edit()
      }
    },
    _getA25List () {
      this.searchModel.tenderGuid = this.tenderGuid
      this.searchModel.periodGuid = this.periodGuid
      a25Service.list({}, this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.a25List = res.data.data.map(a25 => {
              if (a25.listName.indexOf('回扣') > -1) {
                a25.listName = `${a25.listName}（填负数，如：-1000）`
              }
              return a25
            })
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _edit () {
      a25Service.edit(this.a25Model)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this._getA25List()
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this._getA25List()
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getA25List()
    this.refreshMethod = this._getA25List
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
::v-deep .editable {
  color: red;
}
</style>
