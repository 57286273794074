import utility from '@/common/utility'
import auth from '@/common/auth'

export default class A15Model {
  constructor (props) {
    if (props) {
      this.resourceId = props.a15Guid
      this.a15Guid = props.a15Guid
      this.examinationGuid = props.examinationGuid
      this.meterageListGuid = props.meterageListGuid
      this.chapter = props.chapter
      this.meterageCode = props.meterageCode
      this.meterageDisplayCode = props.meterageDisplayCode
      this.meterageName = props.meterageName
      this.unit = props.unit
      this.num = props.num
      this.price = props.price
      this.amount = props.amount
      this.accumulateNum = props.accumulateNum
      this.declarationNum = props.declarationNum
      this.startMileage = props.startMileage
      this.endMileage = props.endMileage
      this.part = props.part
      this.pictureNo = props.pictureNo
      this.certNo = props.certNo
      this.formula = props.formula
      this.pictureGuid = props.pictureGuid
      this.status = props.status
      this.isChange = props.isChange
      this.isLock = props.isLock
      this.autoSerialNo = props.autoSerialNo
      this.manualSerialNo = props.manualSerialNo
      this.children = props.children
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.tenderGuid = props.tenderGuid
      this.a15Date = props.a15Date
      this.directive = props.directive
      this.remark = props.remark
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.a15Guid) this.a15Guid = ''
    if (!this.examinationGuid) this.examinationGuid = ''
    if (!this.meterageListGuid) this.meterageListGuid = ''
    if (!this.chapter) this.chapter = 0
    if (!this.meterageCode) this.meterageCode = ''
    if (!this.meterageDisplayCode) this.meterageDisplayCode = ''
    if (!this.meterageName) this.meterageName = ''
    if (!this.unit) this.unit = ''
    if (!this.num) this.num = 0
    if (!this.price) this.price = 0
    if (!this.amount) this.amount = 0
    if (!this.accumulateNum) this.accumulateNum = 0
    if (!this.declarationNum) this.declarationNum = 0
    if (!this.startMileage) this.startMileage = ''
    if (!this.endMileage) this.endMileage = ''
    if (!this.part) this.part = ''
    if (!this.pictureNo) this.pictureNo = ''
    if (!this.certNo) this.certNo = ''
    if (!this.formula) this.formula = ''
    if (!this.pictureGuid) this.pictureGuid = ''
    if (!this.status) this.status = ''
    if (!this.isChange) this.isChange = 0
    if (!this.isLock) this.isLock = 0
    if (!this.children) this.children = []
    if (!this.autoSerialNo) this.autoSerialNo = ''
    if (!this.manualSerialNo) this.manualSerialNo = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getUuid()
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.a15Date) this.a15Date = ''
    if (!this.directive) this.directive = ''
    if (!this.remark) this.remark = ''
  }

  generatePrimaryKey () {
    this.a15Guid = utility.getUuid()
    this.resourceId = this.a15Guid
  }
}
